.inputs-group {
  svg {
    width: 25px;
    height: 25px;
  }
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 8px rgba(0, 180, 188, 0.17);
  padding: 20px 50px;

  .ant-form-item {
    margin-bottom: 0;
    border-bottom: 1px solid #bdb2b2;

    &:last-child {
      margin-bottom: 2rem;
      border-bottom: none;
    }

    .ant-form-explain {
      padding-left: 1rem;
    }
    .ant-form-item-label {
      color: #00b4bc;
    }
  }

  span.ant-input-affix-wrapper {
    box-shadow: none;
  }

  textarea.ant-input,
  input.ant-input {
    box-shadow: none;
    border: none;
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}

.hidden-comps-webview {
  display: none !important;
}
.login-number-field {
  margin-left: 1em !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #00888e !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #00888e !important;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 15px !important;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
#dealerSidebarId:hover {
  max-width: 210px !important;
  min-width: 210px !important;
  z-index: 999 !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #ffffff !important;
  text-align: center;

  background: #00888e !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  text-align: center;
  width: 14.5vw;
}
.ant-menu-item > a {
  margin-left: 5px;
  display: inline !important;
  font-weight: 500;
}
.welcome-sign-up-text {
  display: none;
}
.submit-button-sign-in {
  display: none !important;
}
.submit-button-sign-up {
  display: none !important;
}
.sign-in-logo {
  position: absolute;
  margin-top: 1em !important;
  margin-bottom: -1em !important;
  left: 0 !important;
  top: 0;
  width: 12em;
  display: inherit !important;
}
.sign-in-logo-right {
  position: absolute;
  margin-top: 1em !important;
  margin-bottom: -1em !important;
  right: 0 !important;
  top: 0;
  width: 12em;
  display: inherit !important;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px solid rgba(107, 122, 122, 0.137) !important;
}
.my-vehicles-list-hidden-mobile {
  display: none !important;
}
.car-view-pic-row-mobile {
  display: none !important;
}
@media only screen and (max-width: 770px) {
  .welcome-sign-up-text {
    display: inherit;
    margin-top: 3em;
    margin-bottom: 0.2em;
    color: #00888e !important;
    font-size: 32px !important;
  }
  .create-sign-up-text {
    font-size: 23px !important;
  }
  .sign-in-left {
    display: none !important;
  }
  .sign-up-right {
    margin-left: 4.3em !important;
  }

  .ant-tabs-card-bar {
    text-align: center;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: auto !important;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
    border-radius: 3em !important;
    width: 40vw !important;
  }
  .ant-tabs-bar {
    margin: auto !important;
    width: 80vw !important;
    border-bottom: 0px solid #ffffff !important;
    margin-bottom: 1em !important;
  }
  .submit-button-customer {
    margin: auto !important;
    height: 3em !important;

    font-size: 20px !important;
    display: inherit !important;
  }
  .submit-button-sign-in {
    margin: auto !important;
    font-weight: 500 !important;
    color: grey !important;
    box-shadow: none !important;
    margin-top: 0.5em !important;
    height: 3em !important;
    border: none !important;
    font-size: 15px !important;
    display: inherit !important;
    width: 86vw !important;
  }
  .submit-button-sign-up {
    box-shadow: none !important;
    margin: auto !important;
    font-weight: 500 !important;
    color: grey !important;
    margin-top: 1em !important;
    height: 3em !important;
    font-size: 15px !important;
    border: none !important;
    display: inherit !important;
    width: 94vw !important;
  }
  .ant-layout-content {
    background-color: white;
  }
  .sign-in-logo {
    z-index: 9;
    position: absolute;
    margin-top: 1em !important;
    margin-bottom: -1em !important;
    left: 0em !important;
    top: 0;
    width: 8em;
    display: inherit !important;
  }
  .sign-in-logo-right {
    z-index: 9;
    position: absolute;
    margin-top: 1em !important;
    margin-bottom: -1em !important;
    left: 0em !important;
    top: 0;
    width: 8em;
    display: inherit !important;
  }
  .ant-tabs-bar {
    margin: auto !important;
    width: 100% !important;
    margin: auto !important;
    border-bottom: 1px solid #e8e8e8;
    outline: none;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-collapse-content {
    overflow: visible !important;
  }
  #dealerSidebarId {
    display: none !important;
  }
  .login-number-field {
    margin-left: 0em !important;
  }
  .burger-menu {
    left: -1em;
    margin-top: -0.15em !important;
    visibility: visible !important;
  }
  .header-logo {
    margin-top: 0.7em !important;
    margin-left: 3em !important;
  }
  .ant-drawer .ant-drawer-content {
    width: 190px !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
    padding-top: 40px !important;
  }
  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    width: 190px !important;
  }

  .mobile-content-layout {
    margin: 0px !important;
  }
  .ant-select-dropdown-menu-item {
    white-space: pre-wrap !important;
  }
  .hidden-comps-mobile {
    display: none !important;
  }
  .hidden-comps-webview {
    display: inherit !important;
  }
  .collapse-panel-width {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 100vw;
  }
  .ant-collapse-header {
    padding-right: 45px !important;
  }
  .landing-page-header-text {
    margin: auto !important;
    width: 100vw;
  }
  .landing-page-paragraph-text {
    padding-top: 0em !important;
    font-size: 15px !important;
    margin: auto !important;
  }
  .go-dashboard-button {
    margin: auto !important;
    font-size: 17px !important;
    width: 10em !important;
    height: 3em !important;
  }
  .add-car-button {
    margin: auto !important;
    font-size: 17px !important;
    width: 10em !important;
    height: 3em !important;
  }
  #TyreCard .ant-card-meta-title {
    overflow: visible !important;
  }
  #TyreCard .ant-card-body {
    height: 6em;
    padding: 10px !important;
  }
  .my-vehicles-list-hidden {
    display: none !important;
  }
  .my-vehicles-list-hidden-mobile {
    display: inherit !important;
  }
  .my-vehicles-header {
    float: none !important;
    text-align: center;

    margin-bottom: 0.7em;
  }
  .damage-img-1 {
    display: none !important;
    width: 1em !important;
  }
  .car-view-pic-row-web {
    display: none !important;
  }
  .car-view-pic-row-mobile {
    margin-left: -2em;
    width: 120vw !important;
    display: inherit !important;
  }
  .large-image-modal > .ant-modal-content {
    -webkit-box-shadow: none;
    box-shadow: none !important;
    background-color: transparent !important;
    top: -em !important;

    min-width: 85vw !important;
  }
  .large-image-modal > .ant-modal-confirm {
    min-width: 100vw !important;
  }

  .large-image-modal-img {
    visibility: visible;
    top: -13em !important;
    left: -7em !important;
    position: absolute !important;
    width: 100vw !important;
  }
}
